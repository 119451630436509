<template>
  <div>
    <title-header :title="headerText"></title-header>
    <v-form v-model="validData" class="ml-10">
      <v-container>
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field outlined label="*Escribe tu pregunta" v-model="field.name" :rules="rules.name"></v-text-field>
            <v-text-field outlined label="*Escribe la descripción" v-model="field.description" :rules="rules.name">
            </v-text-field>
            <v-select outlined :items="fieldTypes" v-model="field.fieldType" item-text="name" label="*Tipo"
              item-value="slug" return-object @change="onChangeFieldType"></v-select>
            <!-- <div v-if="hasValidations && field.fieldType != 'date'">
              <v-row>
                <v-col md="6">
                  <v-text-field outlined label="Escribe el valor minimo de caracteres" type="number" v-model="field.validationMin"
                    :rules="[( this.field.validationMin >= 0) || 'No puede ser menor a 0']" />
                </v-col>
                <v-col md="6">
                  <v-text-field outlined label="Escribe el valor máximo de caracteres" type="number" v-model="field.validationMax"
                    :rules="[( this.field.validationMax >= 0) || 'No puede ser menor a 0']" />
                </v-col>
              </v-row>
              <v-text-field outlined label="Escribe el campo de validación (Ejem. ^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$)" v-model="field.validationRegex" />
            </div>
            <div v-else-if="hasValidations">
              <v-row>
                <v-col md="6">
                  <v-menu
                    ref="menu-date-min"
                    v-model="menuDateMin"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        outlined
                        v-model="field.minDate"
                        label="Fecha mínima"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="field.minDate"
                      :active-picker.sync="activePicker"
                      :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col md="6">
                  <v-menu
                    ref="menu-date-max"
                    v-model="menuDateMax"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        outlined
                        v-model="field.maxDate"
                        label="Fecha máxima"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="field.maxDate"
                      :active-picker.sync="activePicker"
                      :min="field.minDate"
                      :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </div> -->
            <v-select outlined :items="issueTypes" v-model="field.issueType" item-text="name" item-value="id"
              label="*Tipo de problema"></v-select>
            <p class="ml-2">
              <small>*Campos obligatarios</small>
            </p>
            <v-btn rounded outlined color="primary" dark @click="cancel()">Cancelar</v-btn>
            <v-btn class="ml-6" rounded color="primary" @click="createClicked()" :disabled="!validData">{{isComplexField
            ? 'Siguiente' : 'Crear'}}</v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
    <v-dialog v-model="dialog" persistent max-width="480">
      <v-card>
        <v-card-title class="headline">Felicidades</v-card-title>
        <v-card-text>El campo se ha guardado satisfactoriamente.</v-card-text>
        <v-card-text v-if="isComplexField">Ahora define sus opciones</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary darken-1" text @click="acceptDialogClicked()">Aceptar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar color="primary" v-model="showSnackbar">
      {{snackBarText}}
      <v-btn text @click="showSnackbar = false">Cerrar</v-btn>
    </v-snackbar>
  </div>
</template>
<script>
import TitleHeader from '@/components/app/title-header/title-header'
import ComplexFields from '@/constants/complex-fields'
import rules from '@/constants/input-validation'
import {
  FIELDS_CREATE_FIELD, FIELDS_GET_FIELD_TYPES, ISSUE_TYPES_GET_ISSUE_TYPES
} from '@/store/actions.type'
import { FIELDS_SELECT_FIELD } from '@/store/mutations.type'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    TitleHeader
  },
  methods: {
    ...mapActions({
      getFieldTypes: FIELDS_GET_FIELD_TYPES,
      getIssuesTypes: ISSUE_TYPES_GET_ISSUE_TYPES,
      createField: FIELDS_CREATE_FIELD
    }),
    onChangeFieldType(fieldType) {
      this.field.fieldType = fieldType.slug
      this.hasValidations = fieldType.hasValidations
    },
    createClicked() {
      const ftIndex = this.fieldTypes.findIndex(
        ft => ft.slug === this.field.fieldType
      )
      this.field.fieldType = this.fieldTypes[ftIndex]
      const itIndex = this.issueTypes.findIndex(
        it => it.id === this.field.issueType
      )
      this.field.issueType = this.issueTypes[itIndex]
      if (this.field.validationMin === '' && this.field.validationMax === '') {
        this.field.validationMin = null
        this.field.validationMax = null
      }
      if (this.field.allInUppercase) {
        this.field.allInUppercase = true
      }
      this.createField(this.field)
        .then(response => {
          this.field = response.data
          this.dialog = true
        })
        .catch(e => {
          this.snackBarText = e.message
          this.showSnackbar = true
        })
    },
    acceptDialogClicked() {
      if (this.isComplexField || this.hasValidations) {
        this.$store.commit(FIELDS_SELECT_FIELD, this.field)
        this.sendToEdit()
      } else {
        this.cancel()
      }
    },
    cancel() {
      this.$router.push('/settings/fields')
    },
    sendToEdit() {
      this.$router.push('/settings/fields/' + this.field.id + '/edit')
    }
  },
  computed: {
    ...mapGetters({
      fieldTypes: 'getFieldTypes',
      issueTypes: 'getIssueTypesIssueTypes'
    }),
    isComplexField() {
      var is = false
      if (this.field && this.field.fieldType) {
        is = ComplexFields.includes(this.field.fieldType.slug)
      }
      return is
    }
  },
  data() {
    return {
      headerText: 'Crear campo',
      field: {
        name: '',
        description: '',
        fieldType: {},
        issueType: {},
        dateMin: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        dateMax: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        menu: false,
        menu2: false
      },
      hasValidations: false,
      goNext: false,
      validData: false,
      dialog: false,
      showSnackbar: false,
      snackBarText: '',
      rules
    }
  },
  created() {
    this.getFieldTypes()
    this.getIssuesTypes()
  }
}
</script>
